import React from 'react';
import {API} from '../../helpers';

const Chat = () => {
  const [chatLink, setChatLink] = React.useState("");
  React.useEffect(() => {
    var themeMode = localStorage.getItem('theme-mode');
    console.log(themeMode);
    if (themeMode === null) {
      themeMode = 'light';
    } else {
      themeMode = localStorage.getItem('theme-mode');
    }
    API.get('/api/token/chatlink?theme=' + themeMode)
      .then(res => {
        const { success, data } = res.data;
        if (success) {
          console.log(data);
          setChatLink(data);
        } else {
          showError('Unable to connect to the server normally!');
        }
      })
      .catch(error => {
        console.error(error);
        showError('An error occurred while fetching the chat link!');
      });
  }, []);

  return (
    <>
      <iframe
        src={chatLink}
        style={{ width: '100%', height: '85vh', border: 'none' }}
      />
    </>
  );
};

export default Chat;
